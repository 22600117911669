/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n  query searchString($search: String!) {\n    coins {\n      bip44_symbol\n      name\n      address(address: $search) {\n        address\n        addressBlocks(direction: DESC, limit: 1) {\n          items {\n            address {\n              address\n            }\n            height\n            balance\n          }\n        }\n        unconfirmedBalanceChange\n        unconfirmedTxCount\n      }\n      transaction(txid: $search) {\n        txid\n      }\n      block(hash: $search) {\n        hash\n        height\n        txCount\n      }\n    }\n  }\n": types.SearchStringDocument,
    "\n  query searchInt($search: Int!) {\n    coins {\n      bip44_symbol\n      name\n      blockByHeight(height: $search) {\n        height\n        block {\n          hash\n          height\n          txCount\n        }\n      }\n    }\n  }\n": types.SearchIntDocument,
    "\n  query addressCandlesticks($coin: String!, $address: String!, $cursor: OHLCCursor, $limit: Int) {\n    coinBySymbol(symbol: $coin) {\n      bip44_symbol\n      address(address: $address) {\n        address\n        ohlc(direction: DESC, limit: $limit, cursor: $cursor) {\n          items {\n            timestamp\n            open\n            high\n            low\n            close\n          }\n          hasMore\n        }\n      }\n    }\n  }\n": types.AddressCandlesticksDocument,
    "\n  fragment AddressBlockFragment on IAddressBlock {\n    height\n    balance\n    balanceChange\n    txCount\n    blockHash {\n      block {\n        time\n      }\n    }\n  }\n": types.AddressBlockFragmentFragmentDoc,
    "\n  query addressTransactions($coin: String!, $address: String!, $height: Int!) {\n    coinBySymbol(symbol: $coin) {\n      bip44_symbol\n      name\n      address(address: $address) {\n        address\n        addressBlock(height: $height) {\n          height\n          address {\n            address\n          }\n          transactions(direction: ASC) {\n            items {\n              txN\n              ...AddressTransactionFragment\n            }\n          }\n        }\n      }\n    }\n  }\n": types.AddressTransactionsDocument,
    "\n  query addressBlocks($coin: String!, $address: String!, $cursor: AddressBalanceCursor) {\n    coinBySymbol(symbol: $coin) {\n      bip44_symbol\n      name\n      address(address: $address) {\n        address\n        addressBlocks(direction: DESC, cursor: $cursor) {\n          items {\n            address {\n              address\n            }\n            height\n            blockHash {\n              height\n              block {\n                hash\n              }\n            }\n            ...AddressBlockFragment\n          }\n          hasMore\n        }\n      }\n    }\n  }\n": types.AddressBlocksDocument,
    "\n  fragment AddressBlockFragment2 on IAddressBlock {\n    height\n    balance\n    balanceChange\n    txCount\n    blockHash {\n      block {\n        hash\n        time\n      }\n    }\n    transactions(direction: ASC, limit: 3) {\n      ...AddressTransactionsFragment\n    }\n  }\n": types.AddressBlockFragment2FragmentDoc,
    "\n  fragment AddressTransactionsFragment on PaginatedIAddressTransactionResponse {\n    items {\n      txN\n      ...AddressTransactionFragment\n    }\n    hasMore\n  }\n": types.AddressTransactionsFragmentFragmentDoc,
    "\n  query LoadMoreAddressTransactionsQuery(\n    $coin: String!\n    $address: String!\n    $height: Int!\n    $cursor: AddressTransactionCursor\n  ) {\n    coinBySymbol(symbol: $coin) {\n      bip44_symbol\n      address(address: $address) {\n        address\n        addressBlock(height: $height) {\n          address {\n            address\n          }\n          height\n          transactions(direction: ASC, cursor: $cursor) {\n            ...AddressTransactionsFragment\n          }\n        }\n      }\n    }\n  }\n": types.LoadMoreAddressTransactionsQueryDocument,
    "\n  fragment UnconfirmedAddressTransactionsFrament on PaginatedIUnconfirmedAddressTransactionResponse {\n    items {\n      txid\n      balanceChange\n      timestamp\n    }\n    hasMore\n  }\n": types.UnconfirmedAddressTransactionsFramentFragmentDoc,
    "\n  query LoadMoreUnconfirmedAddressTransactionsQuery(\n    $coin: String!\n    $address: String!\n    $cursor: UnconfirmedAddressTransactionCursor\n  ) {\n    coinBySymbol(symbol: $coin) {\n      bip44_symbol\n      address(address: $address) {\n        address\n        unconfirmedTransactions(direction: ASC, cursor: $cursor, limit: 10) {\n          ...UnconfirmedAddressTransactionsFrament\n        }\n      }\n    }\n  }\n": types.LoadMoreUnconfirmedAddressTransactionsQueryDocument,
    "\n  query addressBlocks2($coin: String!, $address: String!, $cursor: AddressBalanceCursor) {\n    coinBySymbol(symbol: $coin) {\n      bip44_symbol\n      name\n      address(address: $address) {\n        address\n        unconfirmedTransactions(direction: ASC, limit: 10) {\n          ...UnconfirmedAddressTransactionsFrament\n        }\n        unconfirmedBalanceChange\n        unconfirmedTxCount\n        addressBlocks(direction: DESC, cursor: $cursor) {\n          items {\n            address {\n              address\n            }\n            height\n            blockHash {\n              height\n              block {\n                time\n                hash\n              }\n            }\n            balance\n            ...AddressBlockFragment2\n          }\n          hasMore\n        }\n      }\n    }\n  }\n": types.AddressBlocks2Document,
    "\n  fragment BlockCardContentFragment on IBlock {\n    height\n    size\n    time\n    difficulty\n  }\n": types.BlockCardContentFragmentFragmentDoc,
    "\n  fragment BlockCardHeaderActionFragment on IBlock {\n    previousBlockHash\n    nextBlock {\n      hash\n    }\n  }\n": types.BlockCardHeaderActionFragmentFragmentDoc,
    "\n  fragment BlockTransactionsFragment on IBlock {\n    txCount\n    transactions(direction: ASC, cursor: $cursor) {\n      items {\n        height\n        txN\n        transaction {\n          txid\n          inputCount\n          outputCount\n          fee\n        }\n      }\n      hasMore\n    }\n  }\n": types.BlockTransactionsFragmentFragmentDoc,
    "\n  query blockTransactionsByHeight(\n    $coin: String!\n    $height: Int!\n    $cursor: ConfirmedTransactionCursor\n  ) {\n    coinBySymbol(symbol: $coin) {\n      bip44_symbol\n      blockByHeight(height: $height) {\n        height\n        block {\n          hash\n          height\n          ...BlockTransactionsFragment\n          ...BlockCardContentFragment\n          ...BlockCardHeaderActionFragment\n        }\n      }\n    }\n  }\n": types.BlockTransactionsByHeightDocument,
    "\n  query blockTransactionsByHash(\n    $coin: String!\n    $hash: String!\n    $cursor: ConfirmedTransactionCursor\n  ) {\n    coinBySymbol(symbol: $coin) {\n      bip44_symbol\n      block(hash: $hash) {\n        hash\n        height\n        ...BlockTransactionsFragment\n        ...BlockCardContentFragment\n        ...BlockCardHeaderActionFragment\n      }\n    }\n  }\n": types.BlockTransactionsByHashDocument,
    "\n  fragment BlocksLoadMoreFragment on PaginatedIBlockHashResponse {\n    ...BlocksTableFragment\n    hasMore\n  }\n": types.BlocksLoadMoreFragmentFragmentDoc,
    "\n  fragment BlocksTableFragment on PaginatedIBlockHashResponse {\n    items {\n      height\n      hash\n      block {\n        height\n        hash\n        txCount\n        size\n        time\n      }\n    }\n  }\n": types.BlocksTableFragmentFragmentDoc,
    "\n  query blocks($coin: String!, $cursor: BlockHashCursor) {\n    coinBySymbol(symbol: $coin) {\n      bip44_symbol\n      blocks(direction: DESC, cursor: $cursor) {\n        ...BlocksTableFragment\n        ...BlocksLoadMoreFragment\n      }\n    }\n  }\n": types.BlocksDocument,
    "\n  query blocksPoller($coin: String!, $cursor: BlockHashCursor) {\n    coinBySymbol(symbol: $coin) {\n      bip44_symbol\n      blocks(direction: ASC, cursor: $cursor) {\n        items {\n          height\n          hash\n          block {\n            height\n            hash\n            txCount\n            size\n            time\n          }\n        }\n      }\n    }\n  }\n": types.BlocksPollerDocument,
    "\n  query coinPageQuery($coin: String!, $date: String!) {\n    coinBySymbol(symbol: $coin) {\n      bip44_symbol\n      blocks(direction: DESC, limit: 5) {\n        ...BlocksTableFragment\n      }\n      mempool {\n        ...MempoolCardContentFragment\n        transactions(direction: DESC, limit: 5) {\n          ...UnconfirmedTransactionsTableFragment\n        }\n      }\n      date(date: $date) {\n        richList(direction: DESC, limit: 5) {\n          ...RichListFragment\n        }\n        topGainers(direction: DESC, limit: 5) {\n          ...TopGainersTableFragment\n        }\n        topLosers(direction: ASC, limit: 5) {\n          ...TopLosersTableFragment\n        }\n      }\n    }\n  }\n": types.CoinPageQueryDocument,
    "\n  query coin($coin: String!) {\n    coinBySymbol(symbol: $coin) {\n      bip44_symbol\n      name\n      blocks(limit: 1, direction: DESC) {\n        items {\n          height\n        }\n      }\n    }\n  }\n": types.CoinDocument,
    "\n  fragment CoinItemFragment on ICoin {\n    blocks(limit: 1, direction: DESC) {\n      items {\n        block {\n          height\n          hash\n          time\n        }\n        hash\n        height\n      }\n    }\n    mempool {\n      totalFees\n      txCount\n    }\n    name\n    bip44_symbol\n  }\n": types.CoinItemFragmentFragmentDoc,
    "\n  query coins {\n    coins {\n      ...CoinItemFragment\n      name\n    }\n  }\n": types.CoinsDocument,
    "\n  fragment AddressTransactionFragment on IAddressTransaction {\n    txN\n    balanceChange\n    confirmedTransaction {\n      height\n      txN\n      txid\n    }\n  }\n": types.AddressTransactionFragmentFragmentDoc,
    "\n  fragment GraphOutput on ITransactionOutput {\n    txid\n    n\n    spendingTxid\n    spendingIndex\n    value\n    scriptPubKey {\n      address {\n        address\n      }\n    }\n  }\n": types.GraphOutputFragmentDoc,
    "\n  fragment GraphTransaction on ITransaction {\n    txid\n    inputCount\n    outputCount\n  }\n": types.GraphTransactionFragmentDoc,
    "\n  fragment GraphInput on ITransactionInput {\n    spendingIndex\n    spentOutput {\n      ...GraphOutput\n    }\n  }\n": types.GraphInputFragmentDoc,
    "\n  query graph_transaction($coin: String!, $txid: String!) {\n    coinBySymbol(symbol: $coin) {\n      bip44_symbol\n      transaction(txid: $txid) {\n        ...GraphTransaction\n        inputs(direction: ASC, limit: 50) {\n          items {\n            ...GraphInput\n          }\n        }\n        outputs(direction: ASC, limit: 50) {\n          items {\n            ...GraphOutput\n          }\n        }\n      }\n    }\n  }\n": types.Graph_TransactionDocument,
    "\n  query graphOutput($coin: String!, $txid: String!, $n: Int!) {\n    coinBySymbol(symbol: $coin) {\n      bip44_symbol\n      transactionOutput(txid: $txid, n: $n) {\n        transaction {\n          ...GraphTransaction\n        }\n        spendingInput {\n          transaction {\n            ...GraphTransaction\n          }\n        }\n        ...GraphOutput\n      }\n    }\n  }\n": types.GraphOutputDocument,
    "\n  fragment MempoolCardContentFragment on IMempool {\n    txCount\n    totalFees\n  }\n": types.MempoolCardContentFragmentFragmentDoc,
    "\n  fragment MempoolCardHeaderFragment on IMempool {\n    txCount\n  }\n": types.MempoolCardHeaderFragmentFragmentDoc,
    "\n  fragment UnconfirmedTransactionsTableFragment on PaginatedIUnconfirmedTransactionResponse {\n    items {\n      txid\n      ...UnconfirmedTransactionFragment\n    }\n    hasMore\n  }\n": types.UnconfirmedTransactionsTableFragmentFragmentDoc,
    "\n  fragment UnconfirmedTransactionFragment on IUnconfirmedTransaction {\n    txid\n    timestamp\n    transaction {\n      txid\n      inputCount\n      outputCount\n    }\n  }\n": types.UnconfirmedTransactionFragmentFragmentDoc,
    "\n  query mempool(\n    $coin: String!\n    $unconfirmedTransactionsCursor: UnconfirmedTransactionCursor\n    $unconfirmedTransactionsLimit: Int\n  ) {\n    coinBySymbol(symbol: $coin) {\n      mempool {\n        ...MempoolCardContentFragment\n        ...MempoolCardHeaderFragment\n        transactions(\n          direction: DESC\n          cursor: $unconfirmedTransactionsCursor\n          limit: $unconfirmedTransactionsLimit\n        ) {\n          ...UnconfirmedTransactionsTableFragment\n        }\n      }\n      bip44_symbol\n    }\n  }\n": types.MempoolDocument,
    "\n  query mempoolPoller($coin: String!) {\n    coinBySymbol(symbol: $coin) {\n      bip44_symbol\n      mempool {\n        ...MempoolCardContentFragment\n        transactions(direction: DESC, limit: 5) {\n          ...UnconfirmedTransactionsTableFragment\n        }\n      }\n    }\n  }\n": types.MempoolPollerDocument,
    "\n  fragment RichListFragment on PaginatedIRichlistResponse {\n    items {\n      address {\n        address\n        addressBlocks(limit: 1, direction: ASC) {\n          items {\n            height\n            address {\n              address\n            }\n            blockHash {\n              height\n              block {\n                hash\n                time\n              }\n            }\n          }\n        }\n      }\n      balance\n      balanceChange\n    }\n    hasMore\n  }\n": types.RichListFragmentFragmentDoc,
    "\n  query richlist(\n    $coin: String!\n    $date: String!\n    $richListCursor: RichListCursor\n    $richListLimit: Int\n  ) {\n    coinBySymbol(symbol: $coin) {\n      bip44_symbol\n      date(date: $date) {\n        richList(direction: DESC, cursor: $richListCursor, limit: $richListLimit) {\n          ...RichListFragment\n        }\n      }\n    }\n  }\n": types.RichlistDocument,
    "\n  fragment TopGainersTableFragment on PaginatedIAddressBalanceChangeResponse {\n    items {\n      address {\n        address\n      }\n      balanceChange\n    }\n    hasMore\n  }\n": types.TopGainersTableFragmentFragmentDoc,
    "\n  query topGainers(\n    $coin: String!\n    $date: String!\n    $topGainersCursor: AddressBalanceChangeCursor\n    $topGainersLimit: Int\n  ) {\n    coinBySymbol(symbol: $coin) {\n      bip44_symbol\n      date(date: $date) {\n        topGainers(direction: DESC, cursor: $topGainersCursor, limit: $topGainersLimit) {\n          ...TopGainersTableFragment\n        }\n      }\n    }\n  }\n": types.TopGainersDocument,
    "\n  fragment TopLosersTableFragment on PaginatedIAddressBalanceChangeResponse {\n    items {\n      address {\n        address\n      }\n      balanceChange\n    }\n    hasMore\n  }\n": types.TopLosersTableFragmentFragmentDoc,
    "\n  query topLosers(\n    $coin: String!\n    $date: String!\n    $topLosersCursor: AddressBalanceChangeCursor\n    $topLosersLimit: Int\n  ) {\n    coinBySymbol(symbol: $coin) {\n      bip44_symbol\n      date(date: $date) {\n        topLosers(direction: ASC, cursor: $topLosersCursor, limit: $topLosersLimit) {\n          ...TopLosersTableFragment\n        }\n      }\n    }\n  }\n": types.TopLosersDocument,
    "\n  fragment TransactionDetails on ITransaction {\n    blockHash {\n      ...BlockLink\n    }\n    fee\n    size\n    ... on DashTransaction {\n      instantlock\n    }\n  }\n": types.TransactionDetailsFragmentDoc,
    "\n  fragment BlockLink on IBlockHash {\n    height\n    hash\n    block {\n      hash\n      time\n    }\n  }\n": types.BlockLinkFragmentDoc,
    "\n  subscription instantSendSubscription($txid: String!) {\n    instantSendTransactionReceived(txid: $txid) {\n      txid\n      instantlock\n    }\n  }\n": types.InstantSendSubscriptionDocument,
    "\n  fragment TransactionInputFragment on ITransactionInput {\n    coinbase\n    txid\n    vout\n    spendingIndex\n    spentOutput {\n      ...ScriptPubkeyFragment\n      value\n    }\n  }\n": types.TransactionInputFragmentFragmentDoc,
    "\n  query LoadMoreTransactionInputs($coin: String!, $txid: String!, $cursor: TransactionInputCursor) {\n    coinBySymbol(symbol: $coin) {\n      bip44_symbol\n      transaction(txid: $txid) {\n        txid\n        inputs(direction: ASC, limit: 100, cursor: $cursor) {\n          items {\n            spendingIndex\n            ...TransactionInputFragment\n          }\n          hasMore\n        }\n      }\n    }\n  }\n": types.LoadMoreTransactionInputsDocument,
    "\n  fragment TransactionInputsFragment on ITransaction {\n    inputs(direction: ASC, limit: 100, cursor: $inputCursor) {\n      items {\n        spendingIndex\n        ...TransactionInputFragment\n      }\n      hasMore\n    }\n    inputCount\n  }\n": types.TransactionInputsFragmentFragmentDoc,
    "\n  fragment OutputFragment on ITransactionOutput {\n    ...ScriptPubkeyFragment\n    n\n    value\n    spendingTxid\n    spendingIndex\n  }\n": types.OutputFragmentFragmentDoc,
    "\n  fragment ScriptPubkeyFragment on ITransactionOutput {\n    scriptPubKey {\n      address {\n        address\n      }\n      asm\n    }\n  }\n": types.ScriptPubkeyFragmentFragmentDoc,
    "\n  query LoadMoreTransactionOutputs(\n    $coin: String!\n    $txid: String!\n    $cursor: TransactionOutputCursor\n  ) {\n    coinBySymbol(symbol: $coin) {\n      bip44_symbol\n      transaction(txid: $txid) {\n        txid\n        outputs(direction: ASC, limit: 100, cursor: $cursor) {\n          items {\n            n\n            ...OutputFragment\n          }\n          hasMore\n        }\n      }\n    }\n  }\n": types.LoadMoreTransactionOutputsDocument,
    "\n  fragment TransactionOutputsFragment on ITransaction {\n    outputs(direction: ASC, limit: 100, cursor: $outputCursor) {\n      items {\n        n\n        ...OutputFragment\n      }\n      hasMore\n    }\n    outputCount\n  }\n": types.TransactionOutputsFragmentFragmentDoc,
    "\n  query transaction(\n    $coin: String!\n    $txid: String!\n    $inputCursor: TransactionInputCursor\n    $outputCursor: TransactionOutputCursor\n  ) {\n    coinBySymbol(symbol: $coin) {\n      bip44_symbol\n      transaction(txid: $txid) {\n        txid\n        ...TransactionDetails\n        ...TransactionInputsFragment\n        ...TransactionOutputsFragment\n      }\n    }\n  }\n": types.TransactionDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query searchString($search: String!) {\n    coins {\n      bip44_symbol\n      name\n      address(address: $search) {\n        address\n        addressBlocks(direction: DESC, limit: 1) {\n          items {\n            address {\n              address\n            }\n            height\n            balance\n          }\n        }\n        unconfirmedBalanceChange\n        unconfirmedTxCount\n      }\n      transaction(txid: $search) {\n        txid\n      }\n      block(hash: $search) {\n        hash\n        height\n        txCount\n      }\n    }\n  }\n"): (typeof documents)["\n  query searchString($search: String!) {\n    coins {\n      bip44_symbol\n      name\n      address(address: $search) {\n        address\n        addressBlocks(direction: DESC, limit: 1) {\n          items {\n            address {\n              address\n            }\n            height\n            balance\n          }\n        }\n        unconfirmedBalanceChange\n        unconfirmedTxCount\n      }\n      transaction(txid: $search) {\n        txid\n      }\n      block(hash: $search) {\n        hash\n        height\n        txCount\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query searchInt($search: Int!) {\n    coins {\n      bip44_symbol\n      name\n      blockByHeight(height: $search) {\n        height\n        block {\n          hash\n          height\n          txCount\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query searchInt($search: Int!) {\n    coins {\n      bip44_symbol\n      name\n      blockByHeight(height: $search) {\n        height\n        block {\n          hash\n          height\n          txCount\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query addressCandlesticks($coin: String!, $address: String!, $cursor: OHLCCursor, $limit: Int) {\n    coinBySymbol(symbol: $coin) {\n      bip44_symbol\n      address(address: $address) {\n        address\n        ohlc(direction: DESC, limit: $limit, cursor: $cursor) {\n          items {\n            timestamp\n            open\n            high\n            low\n            close\n          }\n          hasMore\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query addressCandlesticks($coin: String!, $address: String!, $cursor: OHLCCursor, $limit: Int) {\n    coinBySymbol(symbol: $coin) {\n      bip44_symbol\n      address(address: $address) {\n        address\n        ohlc(direction: DESC, limit: $limit, cursor: $cursor) {\n          items {\n            timestamp\n            open\n            high\n            low\n            close\n          }\n          hasMore\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment AddressBlockFragment on IAddressBlock {\n    height\n    balance\n    balanceChange\n    txCount\n    blockHash {\n      block {\n        time\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment AddressBlockFragment on IAddressBlock {\n    height\n    balance\n    balanceChange\n    txCount\n    blockHash {\n      block {\n        time\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query addressTransactions($coin: String!, $address: String!, $height: Int!) {\n    coinBySymbol(symbol: $coin) {\n      bip44_symbol\n      name\n      address(address: $address) {\n        address\n        addressBlock(height: $height) {\n          height\n          address {\n            address\n          }\n          transactions(direction: ASC) {\n            items {\n              txN\n              ...AddressTransactionFragment\n            }\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query addressTransactions($coin: String!, $address: String!, $height: Int!) {\n    coinBySymbol(symbol: $coin) {\n      bip44_symbol\n      name\n      address(address: $address) {\n        address\n        addressBlock(height: $height) {\n          height\n          address {\n            address\n          }\n          transactions(direction: ASC) {\n            items {\n              txN\n              ...AddressTransactionFragment\n            }\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query addressBlocks($coin: String!, $address: String!, $cursor: AddressBalanceCursor) {\n    coinBySymbol(symbol: $coin) {\n      bip44_symbol\n      name\n      address(address: $address) {\n        address\n        addressBlocks(direction: DESC, cursor: $cursor) {\n          items {\n            address {\n              address\n            }\n            height\n            blockHash {\n              height\n              block {\n                hash\n              }\n            }\n            ...AddressBlockFragment\n          }\n          hasMore\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query addressBlocks($coin: String!, $address: String!, $cursor: AddressBalanceCursor) {\n    coinBySymbol(symbol: $coin) {\n      bip44_symbol\n      name\n      address(address: $address) {\n        address\n        addressBlocks(direction: DESC, cursor: $cursor) {\n          items {\n            address {\n              address\n            }\n            height\n            blockHash {\n              height\n              block {\n                hash\n              }\n            }\n            ...AddressBlockFragment\n          }\n          hasMore\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment AddressBlockFragment2 on IAddressBlock {\n    height\n    balance\n    balanceChange\n    txCount\n    blockHash {\n      block {\n        hash\n        time\n      }\n    }\n    transactions(direction: ASC, limit: 3) {\n      ...AddressTransactionsFragment\n    }\n  }\n"): (typeof documents)["\n  fragment AddressBlockFragment2 on IAddressBlock {\n    height\n    balance\n    balanceChange\n    txCount\n    blockHash {\n      block {\n        hash\n        time\n      }\n    }\n    transactions(direction: ASC, limit: 3) {\n      ...AddressTransactionsFragment\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment AddressTransactionsFragment on PaginatedIAddressTransactionResponse {\n    items {\n      txN\n      ...AddressTransactionFragment\n    }\n    hasMore\n  }\n"): (typeof documents)["\n  fragment AddressTransactionsFragment on PaginatedIAddressTransactionResponse {\n    items {\n      txN\n      ...AddressTransactionFragment\n    }\n    hasMore\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query LoadMoreAddressTransactionsQuery(\n    $coin: String!\n    $address: String!\n    $height: Int!\n    $cursor: AddressTransactionCursor\n  ) {\n    coinBySymbol(symbol: $coin) {\n      bip44_symbol\n      address(address: $address) {\n        address\n        addressBlock(height: $height) {\n          address {\n            address\n          }\n          height\n          transactions(direction: ASC, cursor: $cursor) {\n            ...AddressTransactionsFragment\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query LoadMoreAddressTransactionsQuery(\n    $coin: String!\n    $address: String!\n    $height: Int!\n    $cursor: AddressTransactionCursor\n  ) {\n    coinBySymbol(symbol: $coin) {\n      bip44_symbol\n      address(address: $address) {\n        address\n        addressBlock(height: $height) {\n          address {\n            address\n          }\n          height\n          transactions(direction: ASC, cursor: $cursor) {\n            ...AddressTransactionsFragment\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment UnconfirmedAddressTransactionsFrament on PaginatedIUnconfirmedAddressTransactionResponse {\n    items {\n      txid\n      balanceChange\n      timestamp\n    }\n    hasMore\n  }\n"): (typeof documents)["\n  fragment UnconfirmedAddressTransactionsFrament on PaginatedIUnconfirmedAddressTransactionResponse {\n    items {\n      txid\n      balanceChange\n      timestamp\n    }\n    hasMore\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query LoadMoreUnconfirmedAddressTransactionsQuery(\n    $coin: String!\n    $address: String!\n    $cursor: UnconfirmedAddressTransactionCursor\n  ) {\n    coinBySymbol(symbol: $coin) {\n      bip44_symbol\n      address(address: $address) {\n        address\n        unconfirmedTransactions(direction: ASC, cursor: $cursor, limit: 10) {\n          ...UnconfirmedAddressTransactionsFrament\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query LoadMoreUnconfirmedAddressTransactionsQuery(\n    $coin: String!\n    $address: String!\n    $cursor: UnconfirmedAddressTransactionCursor\n  ) {\n    coinBySymbol(symbol: $coin) {\n      bip44_symbol\n      address(address: $address) {\n        address\n        unconfirmedTransactions(direction: ASC, cursor: $cursor, limit: 10) {\n          ...UnconfirmedAddressTransactionsFrament\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query addressBlocks2($coin: String!, $address: String!, $cursor: AddressBalanceCursor) {\n    coinBySymbol(symbol: $coin) {\n      bip44_symbol\n      name\n      address(address: $address) {\n        address\n        unconfirmedTransactions(direction: ASC, limit: 10) {\n          ...UnconfirmedAddressTransactionsFrament\n        }\n        unconfirmedBalanceChange\n        unconfirmedTxCount\n        addressBlocks(direction: DESC, cursor: $cursor) {\n          items {\n            address {\n              address\n            }\n            height\n            blockHash {\n              height\n              block {\n                time\n                hash\n              }\n            }\n            balance\n            ...AddressBlockFragment2\n          }\n          hasMore\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query addressBlocks2($coin: String!, $address: String!, $cursor: AddressBalanceCursor) {\n    coinBySymbol(symbol: $coin) {\n      bip44_symbol\n      name\n      address(address: $address) {\n        address\n        unconfirmedTransactions(direction: ASC, limit: 10) {\n          ...UnconfirmedAddressTransactionsFrament\n        }\n        unconfirmedBalanceChange\n        unconfirmedTxCount\n        addressBlocks(direction: DESC, cursor: $cursor) {\n          items {\n            address {\n              address\n            }\n            height\n            blockHash {\n              height\n              block {\n                time\n                hash\n              }\n            }\n            balance\n            ...AddressBlockFragment2\n          }\n          hasMore\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment BlockCardContentFragment on IBlock {\n    height\n    size\n    time\n    difficulty\n  }\n"): (typeof documents)["\n  fragment BlockCardContentFragment on IBlock {\n    height\n    size\n    time\n    difficulty\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment BlockCardHeaderActionFragment on IBlock {\n    previousBlockHash\n    nextBlock {\n      hash\n    }\n  }\n"): (typeof documents)["\n  fragment BlockCardHeaderActionFragment on IBlock {\n    previousBlockHash\n    nextBlock {\n      hash\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment BlockTransactionsFragment on IBlock {\n    txCount\n    transactions(direction: ASC, cursor: $cursor) {\n      items {\n        height\n        txN\n        transaction {\n          txid\n          inputCount\n          outputCount\n          fee\n        }\n      }\n      hasMore\n    }\n  }\n"): (typeof documents)["\n  fragment BlockTransactionsFragment on IBlock {\n    txCount\n    transactions(direction: ASC, cursor: $cursor) {\n      items {\n        height\n        txN\n        transaction {\n          txid\n          inputCount\n          outputCount\n          fee\n        }\n      }\n      hasMore\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query blockTransactionsByHeight(\n    $coin: String!\n    $height: Int!\n    $cursor: ConfirmedTransactionCursor\n  ) {\n    coinBySymbol(symbol: $coin) {\n      bip44_symbol\n      blockByHeight(height: $height) {\n        height\n        block {\n          hash\n          height\n          ...BlockTransactionsFragment\n          ...BlockCardContentFragment\n          ...BlockCardHeaderActionFragment\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query blockTransactionsByHeight(\n    $coin: String!\n    $height: Int!\n    $cursor: ConfirmedTransactionCursor\n  ) {\n    coinBySymbol(symbol: $coin) {\n      bip44_symbol\n      blockByHeight(height: $height) {\n        height\n        block {\n          hash\n          height\n          ...BlockTransactionsFragment\n          ...BlockCardContentFragment\n          ...BlockCardHeaderActionFragment\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query blockTransactionsByHash(\n    $coin: String!\n    $hash: String!\n    $cursor: ConfirmedTransactionCursor\n  ) {\n    coinBySymbol(symbol: $coin) {\n      bip44_symbol\n      block(hash: $hash) {\n        hash\n        height\n        ...BlockTransactionsFragment\n        ...BlockCardContentFragment\n        ...BlockCardHeaderActionFragment\n      }\n    }\n  }\n"): (typeof documents)["\n  query blockTransactionsByHash(\n    $coin: String!\n    $hash: String!\n    $cursor: ConfirmedTransactionCursor\n  ) {\n    coinBySymbol(symbol: $coin) {\n      bip44_symbol\n      block(hash: $hash) {\n        hash\n        height\n        ...BlockTransactionsFragment\n        ...BlockCardContentFragment\n        ...BlockCardHeaderActionFragment\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment BlocksLoadMoreFragment on PaginatedIBlockHashResponse {\n    ...BlocksTableFragment\n    hasMore\n  }\n"): (typeof documents)["\n  fragment BlocksLoadMoreFragment on PaginatedIBlockHashResponse {\n    ...BlocksTableFragment\n    hasMore\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment BlocksTableFragment on PaginatedIBlockHashResponse {\n    items {\n      height\n      hash\n      block {\n        height\n        hash\n        txCount\n        size\n        time\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment BlocksTableFragment on PaginatedIBlockHashResponse {\n    items {\n      height\n      hash\n      block {\n        height\n        hash\n        txCount\n        size\n        time\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query blocks($coin: String!, $cursor: BlockHashCursor) {\n    coinBySymbol(symbol: $coin) {\n      bip44_symbol\n      blocks(direction: DESC, cursor: $cursor) {\n        ...BlocksTableFragment\n        ...BlocksLoadMoreFragment\n      }\n    }\n  }\n"): (typeof documents)["\n  query blocks($coin: String!, $cursor: BlockHashCursor) {\n    coinBySymbol(symbol: $coin) {\n      bip44_symbol\n      blocks(direction: DESC, cursor: $cursor) {\n        ...BlocksTableFragment\n        ...BlocksLoadMoreFragment\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query blocksPoller($coin: String!, $cursor: BlockHashCursor) {\n    coinBySymbol(symbol: $coin) {\n      bip44_symbol\n      blocks(direction: ASC, cursor: $cursor) {\n        items {\n          height\n          hash\n          block {\n            height\n            hash\n            txCount\n            size\n            time\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query blocksPoller($coin: String!, $cursor: BlockHashCursor) {\n    coinBySymbol(symbol: $coin) {\n      bip44_symbol\n      blocks(direction: ASC, cursor: $cursor) {\n        items {\n          height\n          hash\n          block {\n            height\n            hash\n            txCount\n            size\n            time\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query coinPageQuery($coin: String!, $date: String!) {\n    coinBySymbol(symbol: $coin) {\n      bip44_symbol\n      blocks(direction: DESC, limit: 5) {\n        ...BlocksTableFragment\n      }\n      mempool {\n        ...MempoolCardContentFragment\n        transactions(direction: DESC, limit: 5) {\n          ...UnconfirmedTransactionsTableFragment\n        }\n      }\n      date(date: $date) {\n        richList(direction: DESC, limit: 5) {\n          ...RichListFragment\n        }\n        topGainers(direction: DESC, limit: 5) {\n          ...TopGainersTableFragment\n        }\n        topLosers(direction: ASC, limit: 5) {\n          ...TopLosersTableFragment\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query coinPageQuery($coin: String!, $date: String!) {\n    coinBySymbol(symbol: $coin) {\n      bip44_symbol\n      blocks(direction: DESC, limit: 5) {\n        ...BlocksTableFragment\n      }\n      mempool {\n        ...MempoolCardContentFragment\n        transactions(direction: DESC, limit: 5) {\n          ...UnconfirmedTransactionsTableFragment\n        }\n      }\n      date(date: $date) {\n        richList(direction: DESC, limit: 5) {\n          ...RichListFragment\n        }\n        topGainers(direction: DESC, limit: 5) {\n          ...TopGainersTableFragment\n        }\n        topLosers(direction: ASC, limit: 5) {\n          ...TopLosersTableFragment\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query coin($coin: String!) {\n    coinBySymbol(symbol: $coin) {\n      bip44_symbol\n      name\n      blocks(limit: 1, direction: DESC) {\n        items {\n          height\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query coin($coin: String!) {\n    coinBySymbol(symbol: $coin) {\n      bip44_symbol\n      name\n      blocks(limit: 1, direction: DESC) {\n        items {\n          height\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment CoinItemFragment on ICoin {\n    blocks(limit: 1, direction: DESC) {\n      items {\n        block {\n          height\n          hash\n          time\n        }\n        hash\n        height\n      }\n    }\n    mempool {\n      totalFees\n      txCount\n    }\n    name\n    bip44_symbol\n  }\n"): (typeof documents)["\n  fragment CoinItemFragment on ICoin {\n    blocks(limit: 1, direction: DESC) {\n      items {\n        block {\n          height\n          hash\n          time\n        }\n        hash\n        height\n      }\n    }\n    mempool {\n      totalFees\n      txCount\n    }\n    name\n    bip44_symbol\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query coins {\n    coins {\n      ...CoinItemFragment\n      name\n    }\n  }\n"): (typeof documents)["\n  query coins {\n    coins {\n      ...CoinItemFragment\n      name\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment AddressTransactionFragment on IAddressTransaction {\n    txN\n    balanceChange\n    confirmedTransaction {\n      height\n      txN\n      txid\n    }\n  }\n"): (typeof documents)["\n  fragment AddressTransactionFragment on IAddressTransaction {\n    txN\n    balanceChange\n    confirmedTransaction {\n      height\n      txN\n      txid\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment GraphOutput on ITransactionOutput {\n    txid\n    n\n    spendingTxid\n    spendingIndex\n    value\n    scriptPubKey {\n      address {\n        address\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment GraphOutput on ITransactionOutput {\n    txid\n    n\n    spendingTxid\n    spendingIndex\n    value\n    scriptPubKey {\n      address {\n        address\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment GraphTransaction on ITransaction {\n    txid\n    inputCount\n    outputCount\n  }\n"): (typeof documents)["\n  fragment GraphTransaction on ITransaction {\n    txid\n    inputCount\n    outputCount\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment GraphInput on ITransactionInput {\n    spendingIndex\n    spentOutput {\n      ...GraphOutput\n    }\n  }\n"): (typeof documents)["\n  fragment GraphInput on ITransactionInput {\n    spendingIndex\n    spentOutput {\n      ...GraphOutput\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query graph_transaction($coin: String!, $txid: String!) {\n    coinBySymbol(symbol: $coin) {\n      bip44_symbol\n      transaction(txid: $txid) {\n        ...GraphTransaction\n        inputs(direction: ASC, limit: 50) {\n          items {\n            ...GraphInput\n          }\n        }\n        outputs(direction: ASC, limit: 50) {\n          items {\n            ...GraphOutput\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query graph_transaction($coin: String!, $txid: String!) {\n    coinBySymbol(symbol: $coin) {\n      bip44_symbol\n      transaction(txid: $txid) {\n        ...GraphTransaction\n        inputs(direction: ASC, limit: 50) {\n          items {\n            ...GraphInput\n          }\n        }\n        outputs(direction: ASC, limit: 50) {\n          items {\n            ...GraphOutput\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query graphOutput($coin: String!, $txid: String!, $n: Int!) {\n    coinBySymbol(symbol: $coin) {\n      bip44_symbol\n      transactionOutput(txid: $txid, n: $n) {\n        transaction {\n          ...GraphTransaction\n        }\n        spendingInput {\n          transaction {\n            ...GraphTransaction\n          }\n        }\n        ...GraphOutput\n      }\n    }\n  }\n"): (typeof documents)["\n  query graphOutput($coin: String!, $txid: String!, $n: Int!) {\n    coinBySymbol(symbol: $coin) {\n      bip44_symbol\n      transactionOutput(txid: $txid, n: $n) {\n        transaction {\n          ...GraphTransaction\n        }\n        spendingInput {\n          transaction {\n            ...GraphTransaction\n          }\n        }\n        ...GraphOutput\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment MempoolCardContentFragment on IMempool {\n    txCount\n    totalFees\n  }\n"): (typeof documents)["\n  fragment MempoolCardContentFragment on IMempool {\n    txCount\n    totalFees\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment MempoolCardHeaderFragment on IMempool {\n    txCount\n  }\n"): (typeof documents)["\n  fragment MempoolCardHeaderFragment on IMempool {\n    txCount\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment UnconfirmedTransactionsTableFragment on PaginatedIUnconfirmedTransactionResponse {\n    items {\n      txid\n      ...UnconfirmedTransactionFragment\n    }\n    hasMore\n  }\n"): (typeof documents)["\n  fragment UnconfirmedTransactionsTableFragment on PaginatedIUnconfirmedTransactionResponse {\n    items {\n      txid\n      ...UnconfirmedTransactionFragment\n    }\n    hasMore\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment UnconfirmedTransactionFragment on IUnconfirmedTransaction {\n    txid\n    timestamp\n    transaction {\n      txid\n      inputCount\n      outputCount\n    }\n  }\n"): (typeof documents)["\n  fragment UnconfirmedTransactionFragment on IUnconfirmedTransaction {\n    txid\n    timestamp\n    transaction {\n      txid\n      inputCount\n      outputCount\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query mempool(\n    $coin: String!\n    $unconfirmedTransactionsCursor: UnconfirmedTransactionCursor\n    $unconfirmedTransactionsLimit: Int\n  ) {\n    coinBySymbol(symbol: $coin) {\n      mempool {\n        ...MempoolCardContentFragment\n        ...MempoolCardHeaderFragment\n        transactions(\n          direction: DESC\n          cursor: $unconfirmedTransactionsCursor\n          limit: $unconfirmedTransactionsLimit\n        ) {\n          ...UnconfirmedTransactionsTableFragment\n        }\n      }\n      bip44_symbol\n    }\n  }\n"): (typeof documents)["\n  query mempool(\n    $coin: String!\n    $unconfirmedTransactionsCursor: UnconfirmedTransactionCursor\n    $unconfirmedTransactionsLimit: Int\n  ) {\n    coinBySymbol(symbol: $coin) {\n      mempool {\n        ...MempoolCardContentFragment\n        ...MempoolCardHeaderFragment\n        transactions(\n          direction: DESC\n          cursor: $unconfirmedTransactionsCursor\n          limit: $unconfirmedTransactionsLimit\n        ) {\n          ...UnconfirmedTransactionsTableFragment\n        }\n      }\n      bip44_symbol\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query mempoolPoller($coin: String!) {\n    coinBySymbol(symbol: $coin) {\n      bip44_symbol\n      mempool {\n        ...MempoolCardContentFragment\n        transactions(direction: DESC, limit: 5) {\n          ...UnconfirmedTransactionsTableFragment\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query mempoolPoller($coin: String!) {\n    coinBySymbol(symbol: $coin) {\n      bip44_symbol\n      mempool {\n        ...MempoolCardContentFragment\n        transactions(direction: DESC, limit: 5) {\n          ...UnconfirmedTransactionsTableFragment\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment RichListFragment on PaginatedIRichlistResponse {\n    items {\n      address {\n        address\n        addressBlocks(limit: 1, direction: ASC) {\n          items {\n            height\n            address {\n              address\n            }\n            blockHash {\n              height\n              block {\n                hash\n                time\n              }\n            }\n          }\n        }\n      }\n      balance\n      balanceChange\n    }\n    hasMore\n  }\n"): (typeof documents)["\n  fragment RichListFragment on PaginatedIRichlistResponse {\n    items {\n      address {\n        address\n        addressBlocks(limit: 1, direction: ASC) {\n          items {\n            height\n            address {\n              address\n            }\n            blockHash {\n              height\n              block {\n                hash\n                time\n              }\n            }\n          }\n        }\n      }\n      balance\n      balanceChange\n    }\n    hasMore\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query richlist(\n    $coin: String!\n    $date: String!\n    $richListCursor: RichListCursor\n    $richListLimit: Int\n  ) {\n    coinBySymbol(symbol: $coin) {\n      bip44_symbol\n      date(date: $date) {\n        richList(direction: DESC, cursor: $richListCursor, limit: $richListLimit) {\n          ...RichListFragment\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query richlist(\n    $coin: String!\n    $date: String!\n    $richListCursor: RichListCursor\n    $richListLimit: Int\n  ) {\n    coinBySymbol(symbol: $coin) {\n      bip44_symbol\n      date(date: $date) {\n        richList(direction: DESC, cursor: $richListCursor, limit: $richListLimit) {\n          ...RichListFragment\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment TopGainersTableFragment on PaginatedIAddressBalanceChangeResponse {\n    items {\n      address {\n        address\n      }\n      balanceChange\n    }\n    hasMore\n  }\n"): (typeof documents)["\n  fragment TopGainersTableFragment on PaginatedIAddressBalanceChangeResponse {\n    items {\n      address {\n        address\n      }\n      balanceChange\n    }\n    hasMore\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query topGainers(\n    $coin: String!\n    $date: String!\n    $topGainersCursor: AddressBalanceChangeCursor\n    $topGainersLimit: Int\n  ) {\n    coinBySymbol(symbol: $coin) {\n      bip44_symbol\n      date(date: $date) {\n        topGainers(direction: DESC, cursor: $topGainersCursor, limit: $topGainersLimit) {\n          ...TopGainersTableFragment\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query topGainers(\n    $coin: String!\n    $date: String!\n    $topGainersCursor: AddressBalanceChangeCursor\n    $topGainersLimit: Int\n  ) {\n    coinBySymbol(symbol: $coin) {\n      bip44_symbol\n      date(date: $date) {\n        topGainers(direction: DESC, cursor: $topGainersCursor, limit: $topGainersLimit) {\n          ...TopGainersTableFragment\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment TopLosersTableFragment on PaginatedIAddressBalanceChangeResponse {\n    items {\n      address {\n        address\n      }\n      balanceChange\n    }\n    hasMore\n  }\n"): (typeof documents)["\n  fragment TopLosersTableFragment on PaginatedIAddressBalanceChangeResponse {\n    items {\n      address {\n        address\n      }\n      balanceChange\n    }\n    hasMore\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query topLosers(\n    $coin: String!\n    $date: String!\n    $topLosersCursor: AddressBalanceChangeCursor\n    $topLosersLimit: Int\n  ) {\n    coinBySymbol(symbol: $coin) {\n      bip44_symbol\n      date(date: $date) {\n        topLosers(direction: ASC, cursor: $topLosersCursor, limit: $topLosersLimit) {\n          ...TopLosersTableFragment\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query topLosers(\n    $coin: String!\n    $date: String!\n    $topLosersCursor: AddressBalanceChangeCursor\n    $topLosersLimit: Int\n  ) {\n    coinBySymbol(symbol: $coin) {\n      bip44_symbol\n      date(date: $date) {\n        topLosers(direction: ASC, cursor: $topLosersCursor, limit: $topLosersLimit) {\n          ...TopLosersTableFragment\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment TransactionDetails on ITransaction {\n    blockHash {\n      ...BlockLink\n    }\n    fee\n    size\n    ... on DashTransaction {\n      instantlock\n    }\n  }\n"): (typeof documents)["\n  fragment TransactionDetails on ITransaction {\n    blockHash {\n      ...BlockLink\n    }\n    fee\n    size\n    ... on DashTransaction {\n      instantlock\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment BlockLink on IBlockHash {\n    height\n    hash\n    block {\n      hash\n      time\n    }\n  }\n"): (typeof documents)["\n  fragment BlockLink on IBlockHash {\n    height\n    hash\n    block {\n      hash\n      time\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  subscription instantSendSubscription($txid: String!) {\n    instantSendTransactionReceived(txid: $txid) {\n      txid\n      instantlock\n    }\n  }\n"): (typeof documents)["\n  subscription instantSendSubscription($txid: String!) {\n    instantSendTransactionReceived(txid: $txid) {\n      txid\n      instantlock\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment TransactionInputFragment on ITransactionInput {\n    coinbase\n    txid\n    vout\n    spendingIndex\n    spentOutput {\n      ...ScriptPubkeyFragment\n      value\n    }\n  }\n"): (typeof documents)["\n  fragment TransactionInputFragment on ITransactionInput {\n    coinbase\n    txid\n    vout\n    spendingIndex\n    spentOutput {\n      ...ScriptPubkeyFragment\n      value\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query LoadMoreTransactionInputs($coin: String!, $txid: String!, $cursor: TransactionInputCursor) {\n    coinBySymbol(symbol: $coin) {\n      bip44_symbol\n      transaction(txid: $txid) {\n        txid\n        inputs(direction: ASC, limit: 100, cursor: $cursor) {\n          items {\n            spendingIndex\n            ...TransactionInputFragment\n          }\n          hasMore\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query LoadMoreTransactionInputs($coin: String!, $txid: String!, $cursor: TransactionInputCursor) {\n    coinBySymbol(symbol: $coin) {\n      bip44_symbol\n      transaction(txid: $txid) {\n        txid\n        inputs(direction: ASC, limit: 100, cursor: $cursor) {\n          items {\n            spendingIndex\n            ...TransactionInputFragment\n          }\n          hasMore\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment TransactionInputsFragment on ITransaction {\n    inputs(direction: ASC, limit: 100, cursor: $inputCursor) {\n      items {\n        spendingIndex\n        ...TransactionInputFragment\n      }\n      hasMore\n    }\n    inputCount\n  }\n"): (typeof documents)["\n  fragment TransactionInputsFragment on ITransaction {\n    inputs(direction: ASC, limit: 100, cursor: $inputCursor) {\n      items {\n        spendingIndex\n        ...TransactionInputFragment\n      }\n      hasMore\n    }\n    inputCount\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment OutputFragment on ITransactionOutput {\n    ...ScriptPubkeyFragment\n    n\n    value\n    spendingTxid\n    spendingIndex\n  }\n"): (typeof documents)["\n  fragment OutputFragment on ITransactionOutput {\n    ...ScriptPubkeyFragment\n    n\n    value\n    spendingTxid\n    spendingIndex\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment ScriptPubkeyFragment on ITransactionOutput {\n    scriptPubKey {\n      address {\n        address\n      }\n      asm\n    }\n  }\n"): (typeof documents)["\n  fragment ScriptPubkeyFragment on ITransactionOutput {\n    scriptPubKey {\n      address {\n        address\n      }\n      asm\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query LoadMoreTransactionOutputs(\n    $coin: String!\n    $txid: String!\n    $cursor: TransactionOutputCursor\n  ) {\n    coinBySymbol(symbol: $coin) {\n      bip44_symbol\n      transaction(txid: $txid) {\n        txid\n        outputs(direction: ASC, limit: 100, cursor: $cursor) {\n          items {\n            n\n            ...OutputFragment\n          }\n          hasMore\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query LoadMoreTransactionOutputs(\n    $coin: String!\n    $txid: String!\n    $cursor: TransactionOutputCursor\n  ) {\n    coinBySymbol(symbol: $coin) {\n      bip44_symbol\n      transaction(txid: $txid) {\n        txid\n        outputs(direction: ASC, limit: 100, cursor: $cursor) {\n          items {\n            n\n            ...OutputFragment\n          }\n          hasMore\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment TransactionOutputsFragment on ITransaction {\n    outputs(direction: ASC, limit: 100, cursor: $outputCursor) {\n      items {\n        n\n        ...OutputFragment\n      }\n      hasMore\n    }\n    outputCount\n  }\n"): (typeof documents)["\n  fragment TransactionOutputsFragment on ITransaction {\n    outputs(direction: ASC, limit: 100, cursor: $outputCursor) {\n      items {\n        n\n        ...OutputFragment\n      }\n      hasMore\n    }\n    outputCount\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query transaction(\n    $coin: String!\n    $txid: String!\n    $inputCursor: TransactionInputCursor\n    $outputCursor: TransactionOutputCursor\n  ) {\n    coinBySymbol(symbol: $coin) {\n      bip44_symbol\n      transaction(txid: $txid) {\n        txid\n        ...TransactionDetails\n        ...TransactionInputsFragment\n        ...TransactionOutputsFragment\n      }\n    }\n  }\n"): (typeof documents)["\n  query transaction(\n    $coin: String!\n    $txid: String!\n    $inputCursor: TransactionInputCursor\n    $outputCursor: TransactionOutputCursor\n  ) {\n    coinBySymbol(symbol: $coin) {\n      bip44_symbol\n      transaction(txid: $txid) {\n        txid\n        ...TransactionDetails\n        ...TransactionInputsFragment\n        ...TransactionOutputsFragment\n      }\n    }\n  }\n"];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;